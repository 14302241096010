import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/app/_utils/ui";

const buttonVariants = cva(
  "inline-flex items-center gap-1 justify-center whitespace-nowrap rounded text-sm transition-all duration-300 ease-in-out focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-30 transition-all ease-in-out select-none",
  {
    variants: {
      variant: {
        default:
          "bg-dark rounded-full text-white text-sm px-6 outline outline-1 outline-transparent hover:outline-offset-4 hover:outline-dark",
        outline:
          "bg-white text-dark rounded-full border border-dark text-sm px-6 outline outline-1 outline-transparent hover:outline-offset-4 hover:outline-dark",
        secondaryNotPhone:
          "bg-white text-dark rounded-full sm:border sm:border-dark text-sm px-6 focus-visible:ouline-none sm:outline sm:outline-1 outline-transparent sm:hover:outline-offset-4 sm:hover:outline-dark",
        primary:
          "bg-dark rounded-full text-white text-sm px-6 outline outline-1 outline-transparent hover:outline-offset-4 hover:outline-dark",
        secondary:
          "bg-white text-dark rounded-full border border-dark text-sm px-6 outline outline-1 outline-transparent hover:outline-offset-4 hover:outline-dark",
        destructive:
          "bg-white text-red-600 rounded-full border border-red-600 text-sm px-6 outline outline-1 outline-transparent hover:outline-offset-4 hover:outline-red-600",
        blue: "bg-blue-tint hover:bg-blue-tint-dark text-white",
        gold: "bg-gold-400 text-dark hover:bg-gold-tint active:bg-gold-400",
        outlineSquare:
          "border border-dark bg-white hover:bg-white hover:text-dark",
        ghost: "hover:bg-neutral-700/10",
        unstyled: "",
        link: "text-dark underline-offset-4 hover:underline",
        action:
          "border border-dark bg-white text-dark hover:bg-white hover:text-dark px-8 !rounded-full text-sm outline outline-1 outline-transparent hover:outline-offset-4 hover:outline-dark",
      },
      size: {
        default: "h-10 px-6 py-2",
        sm: "h-9 px-4",
        lg: "h-11 px-8",
        icon: "h-10 w-10",
      },
      hover: {
        default: "",
        scale: "hover:scale-105 transition-all ease-in-out duration-500",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      hover: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, hover, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, hover, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };

/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ARTICLE_BY_ID_URL,
  IMAGES_GETURL,
  READING_LIST,
} from "@/app/_consts/internal";
import { updateNestedObject } from "@/app/_utils/helper";
import { UploadImageArgs, UploadImageResponse } from "@/app/_types";
import { RootState } from "./store";

const initialState = {
  article: {
    layout: [] as any[],
    title: "",
    huddleQuestions: [],
    id: "",
    author: "",
    deleted: false,
  },
  drafts: {
    all: [] as any[],
    user: [] as any[],
  },
  status: {
    archived: false,
  },
  loading: false,
  error: null as string | null | undefined,
  newComponentIndex: null,
  newArticlePosition: "",
  isSelectingReadingList: false,
};

export const saveArticleAsDraft = createAsyncThunk<any, any>(
  "editArticle/saveArticleAsDraft",
  async (data) => {
    const { accessToken, articleId, layout, title, huddleQuestions } = data;
    const response = await axios.post(
      `${ARTICLE_BY_ID_URL}/${articleId}/draft`,
      { Layout: layout, Title: title, HuddleQuestions: huddleQuestions },
      { headers: { Authorization: accessToken } },
    );
    const updatedArticle = response.data;
    return {
      articleId,
      updatedArticle: updatedArticle.draft,
      draft_time: updatedArticle.draft_time,
      Status: updatedArticle.article_status,
    };
  },
);

export const updateArticle = createAsyncThunk<any, any>(
  "editArticle/updateArticle",
  async (data, { getState }) => {
    const {
      accessToken,
      articleId,
      layout,
      title,
      huddleQuestions,
      readingListData,
      chapterDepth,
      readingList,
    } = data;
    const state = getState() as RootState;

    // Update the article
    const response = await axios.put(
      `${ARTICLE_BY_ID_URL}/${articleId}/edit`,
      { Layout: layout, Title: title, HuddleQuestions: huddleQuestions },
      { headers: { Authorization: accessToken } },
    );
    const updatedArticle = response.data;

    // 2. Reorganize the ReadingList
    const updatedReadingListList = [...readingList.list];
    const articleIndex = updatedReadingListList.findIndex(
      (item) => item.id === articleId,
    );
    if (articleIndex !== -1) {
      updatedReadingListList.splice(articleIndex, 1); // Remove the article from its current position
    }
    updatedReadingListList.splice(
      parseInt(state.editArticle.newArticlePosition),
      0,
      {
        id: articleId,
        title: updatedArticle.Title,
        type: "Article",
      },
    ); // Add the article to the new position

    const updatedReadingList = {
      ...readingList,
      list: updatedReadingListList,
    };

    const newReadingList = updateNestedObject(
      readingListData,
      chapterDepth,
      updatedReadingList,
    );

    // 3. Update the  readinglist
    await axios.put(
      `${READING_LIST}/${newReadingList.id}/edit`,
      {
        ...newReadingList,
      },
      { headers: { Authorization: accessToken } },
    );

    return { articleId, updatedArticle };
  },
);

export const uploadImage = createAsyncThunk<
  UploadImageResponse,
  UploadImageArgs
>("editArticle/uploadImage", async (data, { rejectWithValue }) => {
  const { accessToken, file, filename, folder, index, isNewImage } = data;
  const fileExtension = file?.name.split(".").pop();
  try {
    // First I get the presigned URL
    const response = await axios.put(
      `${IMAGES_GETURL}`,
      {
        filename: filename,
        folder: folder,
        "content-type": `image/${fileExtension}`,
      },
      { headers: { Authorization: accessToken } },
    );

    const presignedUrl = response.data;

    // Then I sent a put request to upload the image
    await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": `image/${fileExtension}`,
      },
    });

    // Extract the relative path from the presignedUrl
    const url = new URL(presignedUrl);
    const imageUrl = url.pathname;
    return { success: true, imageUrl, index, type: "image", isNewImage };
  } catch (error) {
    return rejectWithValue({ success: false, error: error });
  }
});

export const archiveArticle = createAsyncThunk<any, any>(
  "editArticle/archiveArticle",
  async (data) => {
    const { accessToken, articleId, archived } = data;
    const response = await axios.put(
      `${ARTICLE_BY_ID_URL}/${articleId}/archived`,
      { archived },
      { headers: { Authorization: accessToken } },
    );

    return {
      articleId,
      Status: response.data.Status,
      updatedArticle: response.data,
    };
  },
);

export const deleteArticle = createAsyncThunk<any, any>(
  "editArticle/deleteArticle",
  async (data) => {
    const { accessToken, articleId, draftTime } = data;
    const response = await axios.put(
      `${ARTICLE_BY_ID_URL}/${articleId}/draft/${draftTime}/delete`,
      {},
      { headers: { Authorization: accessToken } },
    );

    return {
      articleId,
      Status: response.data.Status,
      updatedArticle: response.data,
    };
  },
);

export const deleteDraftArticle = createAsyncThunk<any, any>(
  "editArticle/deleteDraftArticle",
  async (data) => {
    const { accessToken, articleId, archived } = data;
    const response = await axios.put(
      `${ARTICLE_BY_ID_URL}/${articleId}/archived`,
      { archived },
      { headers: { Authorization: accessToken } },
    );
    return { articleId, Status: response.data.Status };
  },
);

export const getArticleDrafts = createAsyncThunk<any, any>(
  "editArticle/getArticleDrafts",
  async (data) => {
    const { accessToken, articleId } = data;
    const response = await axios.get(
      `${ARTICLE_BY_ID_URL}/${articleId}/drafts`,
      { headers: { Authorization: accessToken } },
    );

    const drafts = response.data;
    return { articleId, drafts };
  },
);

const editArticleSlice = createSlice({
  name: "editArticle",
  initialState,
  reducers: {
    setEditArticle(state, action) {
      state.article = action.payload;
    },
    setLayoutArticle(state, action) {
      const { index, item } = action.payload;
      state.article.layout[index] = item;
    },
    setArticleTitle(state, action) {
      state.article.title = action.payload.title;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setNewEditArticlePosition(state, action) {
      state.newArticlePosition = action.payload;
    },
    insertComponentIntoLayout(state, action) {
      const { index, position, item } = action.payload;
      if (position === "top") {
        state.article.layout = [
          ...state.article.layout.slice(0, index),
          item,
          ...state.article.layout.slice(index),
        ];
        state.newComponentIndex = index;
      } else if (position === "bottom") {
        state.article.layout = [
          ...state.article.layout.slice(0, index + 1),
          item,
          ...state.article.layout.slice(index + 1),
        ];
        state.newComponentIndex = index + 1;
      }
    },
    deleteComponentFromLayout(state, action) {
      const index = action.payload;
      state.article.layout.splice(index, 1);
    },
    setArticleDrafts(state, action) {
      const { all, user } = action.payload;
      state.drafts.all = all;
      state.drafts.user = user;
    },
    clearNewComponentIndex(state) {
      state.newComponentIndex = null;
    },
    setHuddleQuestions(state, action) {
      state.article.huddleQuestions = action.payload;
    },
    setIsSelectingReadingList(state, action) {
      state.isSelectingReadingList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateArticle.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateArticle.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.article.layout = action.payload.updatedArticle.Layout;
        state.article.huddleQuestions =
          action.payload.updatedArticle.HuddleQuestions;
      })
      .addCase(updateArticle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getArticleDrafts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getArticleDrafts.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        if (action.payload.drafts) {
          const { article_drafts, user_drafts } = action.payload.drafts;

          state.drafts.all = article_drafts
            ? article_drafts.map((draft: any) => ({
                AuthorName: draft.AuthorName,
                HuddleQuestions: draft.HuddleQuestions,
                Layout: draft.Layout,
                Title: draft.Title,
                draft_time: draft.draft_time,
              }))
            : [];

          state.drafts.user = user_drafts
            ? user_drafts.map((draft: any) => ({
                AuthorName: draft.AuthorName,
                HuddleQuestions: draft.HuddleQuestions,
                Layout: draft.Layout,
                Title: draft.Title,
                draft_time: draft.draft_time,
              }))
            : [];
        } else {
          state.drafts.all = [];
          state.drafts.user = [];
        }
      })
      .addCase(getArticleDrafts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(saveArticleAsDraft.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveArticleAsDraft.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        const savedDraft = {
          AuthorName: action.payload.updatedArticle.DraftedBy,
          Layout: action.payload.updatedArticle.Layout,
          Title: action.payload.updatedArticle.Title,
          HuddleQuestions: action.payload.updatedArticle.HuddleQuestions,
          draft_time: action.payload.draft_time,
        };

        state.drafts.user = [savedDraft, ...state.drafts.user];
        state.drafts.all = [savedDraft, ...state.drafts.all];
      })
      .addCase(saveArticleAsDraft.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        const { imageUrl, index, type, isNewImage } = action.payload;

        // Insert the image into layout
        if (isNewImage) {
          state.article.layout = [
            ...state.article.layout.slice(0, index + 1),
            { src: imageUrl, type },
            ...state.article.layout.slice(index + 1),
          ];
        } else {
          state.article.layout[index] = { src: imageUrl, type };
        }
      })
      .addCase(archiveArticle.fulfilled, (state, action) => {
        state.status = action.payload.Status;
      });
  },
});

export const {
  setEditArticle,
  setLoading,
  setError,
  setLayoutArticle,
  setArticleTitle,
  insertComponentIntoLayout,
  deleteComponentFromLayout,
  clearNewComponentIndex,
  setHuddleQuestions,
  setArticleDrafts,
  setNewEditArticlePosition,
  setIsSelectingReadingList,
} = editArticleSlice.actions;

export default editArticleSlice.reducer;
